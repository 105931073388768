import React from 'react';
import ActionDropdown from 'components/ActionDropdown';
import PropTypes from 'prop-types';

const DropdownItemId = Object.freeze({
  DOWNLOAD: 'download',
});

const CommissionsActionDropdown = ({ onDownload, isDisabled }) => {
  const dropdownItem = [
    {
      id: DropdownItemId.DOWNLOAD,
      icon: <i className="mdi mdi-cloud-download-outline mr-2 font-size-18" />,
      label: 'Download',
    },
  ];

  const onItemClickHandler = item => {
    const itemHandlers = {
      [DropdownItemId.DOWNLOAD]: onDownload,
    };

    if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  return (
    <ActionDropdown
      items={dropdownItem}
      onItemClick={onItemClickHandler}
      isDisabled={isDisabled}
    />
  );
};

CommissionsActionDropdown.propTypes = {
  onDownload: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default CommissionsActionDropdown;
