import { all, takeLatest, put, call } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { showErrorMessage } from 'utils/notification';
import api from 'api';
import { actions } from './slice';
import { generateURL } from 'utils/generateURL';
import { COMMISSIONS_STATUSES } from 'constants/commissions';

export function* fetchCommissionsSaga(action) {
  const { payload } = action;
  const { requestURL } = yield generateURL({
    payload,
    requestURL: '/reports/commissions/',
    options: {
      arrayFormat: 'comma',
    },
  });

  yield api({
    action,
    method: 'get',
    url: requestURL,
  });
}

export function* fetchCommissionsReportSaga(action) {
  const { payload } = action;
  const { requestURL } = yield generateURL({
    payload,
    requestURL: '/reports/commissions/',
    options: {
      arrayFormat: 'comma',
    },
  });
  yield api({
    action,
    method: 'get',
    url: requestURL,
    headers: {
      Accept: 'application/xlsx',
    },
    responseType: 'blob',
    showResponseError: true,
  });
}

export function* fetchCommissionsReportSuccessSaga({ payload }) {
  try {
    const { data } = payload;
    const filename = `commisions_report.xlsx`;
    yield call(saveAs, data, filename);
  } catch (err) {
    showErrorMessage(
      'Error!',
      'Something went wrong while downloading XLSX file'
    );
  }
}

export function* fetchCommissionsInvoiceSaga(action) {
  const { payload } = action;
  const { requestURL } = yield generateURL({
    payload,
    requestURL: `/reports/commissions/${action.payload}/form/`,
    options: {
      arrayFormat: 'comma',
    },
  });
  yield api({
    action,
    method: 'get',
    url: requestURL,
    headers: {
      Accept: 'application/xlsx',
    },
    responseType: 'blob',
    showResponseError: true,
  });
}

export function* fetchCommissionsInvoiceSuccessSaga({ payload }) {
  try {
    const { data } = payload;
    const filename = `commisions_report.pdf`;
    yield call(saveAs, data, filename);
  } catch (err) {
    showErrorMessage(
      'Error!',
      'Something went wrong while downloading XLSX file'
    );
  }
}

export function* fetchDisputedCommissionsSaga(action) {
  const { requestURL } = yield generateURL({
    payload: {
      status: [COMMISSIONS_STATUSES.DISPUTED],
    },
    requestURL: '/reports/commissions/',
    options: {
      arrayFormat: 'comma',
    },
  });

  yield api({
    action,
    method: 'get',
    url: requestURL,
  });
}

export function* fetchCommissionByIdSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/reports/commissions/${action.payload}/`,
  });
}

export function* fetchCommissionByIdSuccessSaga(action) {
  const commission = action.payload.data;
  const ordersInfo = commission?.orders_info ?? {};
  const ordersId = Object.keys(ordersInfo);

  const ordersRequest = yield ordersId.map(id =>
    api({
      method: 'get',
      url: `/orders/${id}`,
      withResponse: true,
    })
  );

  const response = yield all(ordersRequest);
  yield put({
    type: actions.setCurrentCommission,
    payload: {
      ...commission,
      ordersInfo: response.map(orderItem => ({
        ...orderItem,
        ...(ordersInfo?.[orderItem.id] ?? {}),
      })),
    },
  });
}

export function* updateCommissionSaga(action) {
  const { payload } = action;
  yield api({
    action,
    url: `/reports/commissions/${payload.id}/`,
    data: payload,
    method: 'PUT',
    successNavigateTo: '/reports/commissions',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchCommissions, fetchCommissionsSaga),
    takeLatest(actions.fetchCommissionsReport, fetchCommissionsReportSaga),
    takeLatest(
      actions.fetchCommissionsReportSuccess,
      fetchCommissionsReportSuccessSaga
    ),
    takeLatest(actions.fetchCommissionsInvoice, fetchCommissionsInvoiceSaga),
    takeLatest(
      actions.fetchCommissionsInvoiceSuccess,
      fetchCommissionsInvoiceSuccessSaga
    ),
    takeLatest(actions.fetchDisputedCommissions, fetchDisputedCommissionsSaga),
    takeLatest(actions.fetchCommissionById, fetchCommissionByIdSaga),
    takeLatest(actions.updateCommission, updateCommissionSaga),
    takeLatest(
      actions.fetchCommissionByIdSuccess,
      fetchCommissionByIdSuccessSaga
    ),
  ]);
}
